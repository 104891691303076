import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';


import styles from './SignUp.css';
import { validationSchema } from './validationSchemas/SignUpSchema';
import { Checkbox } from '../../../../atoms/Checkbox/Checkbox';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { CheckboxTypes } from '../../../../atoms/Icons/Styleguide/CheckboxIcon';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Divider } from '../../../../molecules/Divider/Divider';
import {
  PASSWORD_VALIDATION_INSTRUCTIONS_IDS,
  ValidationHints
} from '../../../../molecules/ValidationHints/ValidationHints';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import UserService, { AuthType, ERRORS_DESCRIPTION } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { SocialButtons } from '../../../SocialButtons/SocialButtons';
import stylesCommon from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';

const SignUp = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authDataContext = useContext(AuthDataContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: authDataContext.value.email || '',
      password: ''
    }
  });
  const values = watch();
  const { email, password } = values;
  const [checkBox, setCheckbox] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();
  const game = gameArkadiumSlug
    ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
    : '';
  const onSubmit = () => {
    setIsLoading(true);
    void fetchGetUserByEmail();
  };
  const fetchGetUserByEmail = async () => {
    try {
      await UserService.getUserByEmail({ email });
      handleGetUserByEmailSuccess();
    } catch (err) {
      void fetchCheckPasswordComplexity();
    }
  };
  const handleGetUserByEmailSuccess = () => {
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: ERRORS_DESCRIPTION.UserAlreadyExist,
        type: 'error',
        parentNode: 'rightSideMenu'
      })
    );
    setIsLoading(false);
    // set email to put this on the SIGN_IN screen
    authDataContext.setAuthData({
      ...authDataContext.value,
      email,
      password,
      authProvider: AuthType.EmailPassword
    });
    dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
  };
  const fetchCheckPasswordComplexity = async () => {
    try {
      const { result } = await UserService.checkPasswordComplexity({ password });

      handleCheckPasswordComplexitySuccess(result);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: err,
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const handleCheckPasswordComplexitySuccess = (result: boolean) => {
    setIsLoading(false);

    if (result) {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: ERRORS_DESCRIPTION.PasswordTooEasy,
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    } else {
      // if password is not too easy then go to the next step - PersonalizeProfile screen
      authDataContext.setAuthData({
        ...authDataContext.value,
        authProvider: AuthType.EmailPassword,
        email,
        password,
        checkBox
      });
      dispatch(setSignInUpState(SignInUpComponents.PERSONALIZE_PROFILE));
    }
  };
  const onCheckBoxClick = () => {
    setCheckbox(!checkBox);

    if (!checkBox) {
      void Analytics.trackEvent(Analytics.profile.emailOptIn());
    }
  };

  useEffect(() => {
    // we are making this request here
    // to improve the UX on the PERSONALIZE_PROFILE screen and not wait for a response from the request there
    UserService.generateUserName().then((name) => {
      authDataContext.setAuthData({ ...authDataContext.value, name, authProvider: AuthType.EmailPassword });
    });
  }, []);

  const getBenefits = () => {
    return (
      <ul className={styles.signUpBenefits}>
        <li>
          <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Play 100+ games" />
          <p>{t('SIGN_UP.PLAY_100_GAMES')}</p>
        </li>
        <li>
          <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Save your high scores" />
          <p>{t('SIGN_UP.SAVE_SCORES')}</p>
        </li>
        <li>
          <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Access to special promotions" />
          <p>{t('SIGN_UP.ACCESS_TO_PROMOTIONS')}</p>
        </li>
      </ul>
    );
  };

  return (
    <RightPanelWrapper
      headerLabelKey="SIGN_UP.CREATE_PROFILE"
      topBlock={
        <img
          style={{ marginBottom: 20 }}
          src={`${UrlService.toCDNUrl('/images/signUpHeader.png')}`}
          alt="sign_up_avatars"
        />
      }
      fullWidth
    >
      <div className={styles.wrapper}>
        <I18nText
          className={classNames(stylesCommon.subHeader, styles.subHeader)}
          keyName={'SIGN_UP.CREATE_ACCOUNT_TEXT'}
        />
        {getBenefits()}
      </div>
      <div className={classNames({
        [styles.formWrapper]: !showChallengeRecaptcha,
        [styles.hideContent]: showChallengeRecaptcha
      })}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={stylesCommon.formContent}
          style={{ marginTop: 30 }}
        >
          <div className={styles.inputWrapper}>
            <Input
              required
              className={stylesCommon.input}
              isValid={!errors.email}
              ariaInvalid={errors.email ? 'true' : 'false'}
              register={register}
              name="email"
              type="text"
              placeholder={t('SIGN_UP.ENTER_EMAIL')}
              value={email}
              showError={errors?.email}
              errorMessage={errors?.email?.message ?? ''}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              required
              className={stylesCommon.input}
              isValid={!errors.password}
              ariaInvalid={errors.password ? 'true' : 'false'}
              register={register}
              name="password"
              type="password"
              placeholder={t('SIGN_IN_INPUT_PLACEHOLDER_PASSWORD')}
              value={password}
              ariaDescribedBy={PASSWORD_VALIDATION_INSTRUCTIONS_IDS}
            />
            <ValidationHints password={password} />
          </div>
          <Checkbox
            className={styles.checkboxContainer}
            iconClassName={styles.checkbox}
            labelClassName={styles.checkboxLabel}
            onClick={onCheckBoxClick}
            state={checkBox ? CheckboxTypes.ACTIVE : CheckboxTypes.INACTIVE}
            caption={TranslationService.translateIt('REGISTER_EMAIL_ME')}
            dataElementDescription="radio-button"
            btnType="button"
          />

          <Button loading={isLoading} disabled={!isValid} type="submit" className={styles.submitButton}>
            <I18nText keyName="Submit" as="span" />
          </Button>
          <Divider className={styles.dividerBlock} />
          <SocialButtons
            className={styles.socialButtons}
            game={game}
            category={categoryPageName}
            checkBox={checkBox}
            captchaToken={captchaToken}
            clearCaptchaData={clearCaptchaData}
            getCaptchaToken={getCaptchaToken}
            showChallengeRecaptcha={showChallengeRecaptcha}
            errorMessageSocial={(err) => {
              dispatch(
                setSnackbarData({
                  isOpened: true,
                  message: UserService.errorCodeToText(err),
                  type: 'error',
                  parentNode: 'rightSideMenu'
                })
              );
            }}
          />
          <div className={styles.policy}>
            <a target="_blank" href={UrlService.createPrivacyPolicyURL(currentLang, plusFeatureFlag)} rel="noreferrer noopener">
              <I18nText keyName={'REGISTER_PRIVACY_POLICY'} />
            </a>
          </div>
        </form>
      </div>
      <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} />
    </RightPanelWrapper>
  );
});

export default SignUp;
